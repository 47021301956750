import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBtn,
  CBreadCrumb,
  LWrap,
  CSectTitle,
  CSectTitle03,
  CGeneralMedia,
  CLinkCard02,
  LPickup,
  AssetImage,
  CHeroImg,
  CDefinition,
  CBtnList,
  CAccordionCard,
  LOgata,
  CSliderMedia,
} from '../../../../components/_index';
import menuChoice from '../../../../utils/menu-choice';
import menuGet from '../../../../utils/menu-get';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: 'lunch',
            ja: 'ランチ',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/chefsterrace/lunch/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/chefsterrace/lunch/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
              {
                label: 'シェフズテラス',
                path: '/restaurants/chefsterrace/lunch/',
              },
            ],
            current: {
              label: 'ランチ',
            },
          }}
        />
      </CJumbotron>
      <div className="l_sect" style={{ backgroundColor: '#F6F0EA' }}>
        <section className="u_mbExLarge">
          <LWrap>
            <CSectTitle
              title={{
                en: <>LUNCH</>,
              }}
            />
            <p className="c_sectLead u_mb20">
              東北・宮城の豊かな恵みを料理を通して「素敵な時間」に変える
            </p>
            <p className="c_sectLead">
              海と山に囲まれ、美味しい空気やお水が豊富な東北や宮城で、
              <br />
              丹精込めて育てられた食材をシェフが独創的に活用したお料理の数々
              <br />
              自然豊かなガーデンを眺めながらおたのしみください。
            </p>
            <CHeroImg
              img={{
                src: '/assets/images/restaurants/chefsterrace/lunch/img_hero.png',
                alt: '',
              }}
              exClass=""
            />
          </LWrap>

          <LWrap>
            <CGeneralMedia
              reverse={true}
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/chefsterrace/lunch/img_chefsterrace_lunch.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <span className="u_tal" style={{ display: 'block' }}>
                        DELI BUFFET
                      </span>
                    </>
                  ),
                  text: (
                    <>
                      <p
                        style={{
                          fontFamily: '"Noto Sans JP", sans-serif',
                          fontSize: '1.4rem',
                        }}
                      >
                        契約農家などから届く野菜や、宮城をはじめ東北の食材を
                        <br className="u_pc" />
                        中心に仕上げたサラダやオードブルは
                        <br />
                        ブッフェスタイルでお楽しみいただけます。
                      </p>
                    </>
                  ),
                },
              ]}
            />
            <CGeneralMedia
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/chefsterrace/lunch/img_chefsterrace_lunch02.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <span className="u_tal" style={{ display: 'block' }}>
                        MAIN
                      </span>
                    </>
                  ),
                  text: (
                    <>
                      <p
                        style={{
                          fontFamily: '"Noto Sans JP", sans-serif',
                          fontSize: '1.4rem',
                        }}
                      >
                        オムライスやハンバーグなどの定番メニューから
                        <br className="u_pc" />
                        シェフこだわりの魚介や肉を使ったカジュアルフレンチなど
                        <br className="u_pc" />
                        シーズンごとに数種類のメイン料理からお選びいただけます。
                        <br />
                        シェフのつくりたてをご堪能ください。
                      </p>
                    </>
                  ),
                },
              ]}
            />
            <CGeneralMedia
              reverse={true}
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/chefsterrace/lunch/img_chefsterrace_lunch03.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <span className="u_tal" style={{ display: 'block' }}>
                        SWEETS
                      </span>
                    </>
                  ),
                  text: (
                    <>
                      <p
                        style={{
                          fontFamily: '"Noto Sans JP", sans-serif',
                          fontSize: '1.4rem',
                        }}
                      >
                        季節を感じるフルーツや食材からインスピレーションを得た
                        <br className='u_pc'/>
                        パティシエ自慢のスイーツの数々。
                        <br />
                        タルトやケーキ、焼きたてワッフル、コーヒーや紅茶も
                        <br />
                        ブッフェスタイルでお好きなだけお楽しみいただけます。
                      </p>
                    </>
                  ),
                },
              ]}
            />
            <CGeneralMedia
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/chefsterrace/lunch/img_chefsterrace_lunch04.png',
                    alt: '',
                  },
                  title: (
                    <>
                      <span className="u_tal" style={{ display: 'block' }}>
                        GARDEN TERRACE
                      </span>
                    </>
                  ),
                  text: (
                    <>
                      <p
                        style={{
                          fontFamily: '"Noto Sans JP", sans-serif',
                          fontSize: '1.4rem',
                        }}
                      >
                        天気の心地よい日はテラスに出て過ごすのもおすすめ。
                        <br />
                        食事のあとは、ガーデンをお散歩したり、
                        <br className="u_pc" />
                        みんなで写真を撮ったり、想い出に残るランチタイムを。
                      </p>
                      <figure>
                        <AssetImage
                          src="/assets/images/restaurants/chefsterrace/lunch/img_chefsterrace_lunch05.png"
                          alt=""
                        />
                      </figure>
                    </>
                  ),
                },
              ]}
            />
          </LWrap>
        </section>
      </div>
      <section className="l_sect" style={{ backgroundColor: '#F7EBDD' }}>
        <LWrap>
          <CSectTitle
            title={{
              ja: <>季節のおすすめメニュー</>,
              en: (
                <>
                  SEASON’S <br className="u_sp" />
                  RECOMMEND <br className="u_sp" />
                  MENU
                </>
              ),
            }}
          />
          <CLinkCard02
            align="center"
            data={menuChoice(menuGet(), ['シェフズテラス LUNCH'])}
          />
        </LWrap>
      </section>
      {/* <LOgata /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
